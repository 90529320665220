import staticImageLoader from "@lib/staticImageLoader";
import { Box, Grid, Link as MuiLink, styled, Typography } from "@mui/material";
import { deepOrange, orange } from "@mui/material/colors";
import type { RootState } from "@stores/store";
import Image from "next/image";
import Link from "next/link";
import CommingFashionImage from "public/image/landing_page/category/collection-2022-comming-fashion.png";
import DesignShoesImage from "public/image/landing_page/category/collection-design-shoes.png";
import WorkApparelImage from "public/image/landing_page/category/collection-work-apparel.png";
import * as React from "react";
import { useSelector } from "react-redux";

type Props = {};

const Button = styled("button")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  background: "transparent",
  border: "2px solid",
  borderColor: theme.palette.common.black,
  // borderRadius: 2,
  color: theme.palette.common.black,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightSemiBold,
  fontSize: theme.typography.body1.fontSize,
  height: 40,
  cursor: "pointer",
  boxShadow: "rgb(0 0 0) 3px 3px 0px 0px",
  // "&:hover": {
  //   boxShadow: "rgb(0 0 0) 4px 4px 0px 0px",
  // },
}));

export default function CollectionPannel({}: Props): React.ReactElement {
  const app = useSelector((store: RootState) => store.app);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Box
          sx={{
            bgcolor: orange[100],
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: 1,
            overflow: "hidden",
            position: "relative",
            height: "100%",
            px: 2,
            py: 0,
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "50%",
              },
              height: "100%",
              fontSize: 0,
              mt: "auto",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "block",
                  lg: "none",
                },
              }}
            >
              <Image
                width={1000}
                height={800}
                src={CommingFashionImage}
                alt="New Comming Fashion"
                loader={staticImageLoader}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  lg: "block",
                },
              }}
            >
              <Image
                layout="fill"
                objectFit="cover"
                src={CommingFashionImage}
                alt="New Comming Fashion"
                loader={staticImageLoader}
              />
            </Box>
          </Box>
          <Box
            sx={{
              pl: 2,
              pr: 2,
              flex: 1,
              width: {
                xs: "100%",
                sm: 0,
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                zIndex: 2,
                mt: {
                  xs: 1,
                  sm: "auto",
                },
                mb: {
                  xs: 1.5,
                  sm: "auto",
                },
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              New Comming Fashion
            </Typography>
            <Link href="/custom/collections/fashion-womens-dress" passHref>
              <MuiLink underline="none">
                <Button sx={{ mb: 2 }}>Explore Now</Button>
              </MuiLink>
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Box
          sx={{
            height: "100%",
            bgcolor: "#d7ccc8",
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: 1,
            overflow: "hidden",
            position: "relative",
            // height: 230,
            px: 2,
            py: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              fontSize: 0,
            }}
          >
            <Image
              width={1000}
              height={800}
              src={WorkApparelImage}
              alt="Shirts & hoodies"
              loader={staticImageLoader}
            />
          </Box>
          <Box
            sx={{
              pl: 2,
              pr: 2,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{ zIndex: 2, mt: 1, mb: 1.5 }}
              align="center"
            >
              Shirts & hoodies
            </Typography>
            <Link href="/custom/collections/shirts-and-hoodies" passHref>
              <MuiLink underline="none">
                <Button sx={{ mb: 2 }}>Explore Now</Button>
              </MuiLink>
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Box
          sx={{
            height: "100%",
            bgcolor: deepOrange[100],
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: 1,
            overflow: "hidden",
            position: "relative",
            // height: 230,
            px: 1.5,
            py: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              fontSize: 0,
            }}
          >
            <Image
              width={1000}
              height={800}
              src={DesignShoesImage}
              alt="Customizable Shoes"
              loader={staticImageLoader}
            />
          </Box>
          <Box
            sx={{
              pl: 2,
              pr: 2,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" sx={{ zIndex: 2, my: 1 }} align="center">
              Customizable Shoes
            </Typography>
            <Link href="/custom/collections/customizable-shoes" passHref>
              <MuiLink underline="none">
                <Button sx={{ mb: 2 }}>Explore Now</Button>
              </MuiLink>
            </Link>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
