import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import BrandService from "./brandService";
import CategoryList from "./categoryList";
import CollectionPannel from "./collectionPannel";
import CustomInquire from "./customInquire";
import DesignCase from "./designCase";
import Head from "./head";
import Intergration from "./intergration";
import RecommandProduct from "./recommendProduct";

type Props = {};

export const Container = styled("div")<{ size?: "lg" | "xl" }>(
  ({ theme, size = "lg" }) => ({
    maxWidth:
      size === "lg" ? theme.breakpoints.values.lg : theme.breakpoints.values.xl,
    marginLeft: "auto",
    marginRight: "auto",
    // paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  })
);

export default function CustomHome({}: Props): React.ReactElement {
  return (
    <React.Fragment>
      {/* <Container size="xl"> */}
      <Head />
      {/* </Container> */}
      <Container
        size="xl"
        sx={{
          my: 4,
        }}
      >
        <CollectionPannel />
      </Container>
      <Container size="xl" sx={{ mt: 4, mb: 12 }}>
        <Box sx={{ mt: 4 }}>
          <CategoryList />
        </Box>
      </Container>
      <Container
        size="xl"
        sx={{
          my: 12,
        }}
      >
        <DesignCase />
      </Container>
      <Box
        sx={{
          bgcolor: "grey.100",
        }}
      >
        <Container
          size="xl"
          sx={{
            my: 12,
            py: 6,
          }}
        >
          <BrandService />
        </Container>
      </Box>
      <Container
        size="xl"
        sx={{
          my: 12,
        }}
      >
        <CustomInquire />
      </Container>
      {/* <Container
        size="xl"
        sx={{
          my: 12,
        }}
      >
        <PartnerCase />
      </Container> */}
      <Box
        sx={{
          py: 4,
          bgcolor: "grey.100",
        }}
      >
        <Intergration />
      </Box>
      <Box sx={{ mt: 12 }}>
        <RecommandProduct />
      </Box>
      {/* <Box sx={{ mt: 2 }}>
        <RecentViewProduct />
      </Box> */}
    </React.Fragment>
  );
}
