import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

type Matches = {
  slidesPerView: "auto" | number;
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};

export type MatchMap = {
  xs?: "auto" | number;
  sm?: "auto" | number;
  md?: "auto" | number;
  lg?: "auto" | number;
  xl?: "auto" | number;
};

const defaultMatchMap = {
  xs: "auto",
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
};

export default function useSliderPreview(initMatchMap: MatchMap = {}) {
  const theme = useTheme();
  const matchesXL = useMediaQuery(theme.breakpoints.down("xl"));
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const matchMap = React.useMemo(
    () => Object.assign({}, defaultMatchMap, initMatchMap),
    [initMatchMap, defaultMatchMap]
  );

  return React.useMemo(() => {
    const matches: Matches = {
      slidesPerView: matchMap.xl,
      xs: matchesXS,
      sm: matchesSM,
      md: matchesMD,
      lg: matchesLG,
      xl: matchesXL,
    };
    if (matchesXS) {
      matches.slidesPerView = matchMap.xs;
      return matches;
    }
    if (matchesSM) {
      matches.slidesPerView = matchMap.sm;
      return matches;
    }
    if (matchesMD) {
      matches.slidesPerView = matchMap.md;
      return matches;
    }
    if (matchesLG) {
      matches.slidesPerView = matchMap.lg;
      return matches;
    }
    if (matchesXL) {
      matches.slidesPerView = matchMap.xl;
      return matches;
    }
    return matches;
  }, [matchesXL, matchesLG, matchesMD, matchesSM, matchesXS]);
}
