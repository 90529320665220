import SearchIcon from "@mui/icons-material/SearchRounded";
import { Box, Modal } from "@mui/material";
import Input, { InputAdornment } from "components/input";
import * as React from "react";

type Props = {
  width: number;
  position: { top: number; left: number };
  isOpen: boolean;
  setOpen: () => void;
};

export default function SearchInput({
  width,
  isOpen,
  setOpen,
  position,
}): React.ReactElement {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableRestoreFocus={true}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          width,
          position: "absolute",
        }}
        style={position}
      >
        <Box
          sx={{
            mx: "auto",
            width: "100%",
          }}
        >
          <Input
            sx={{ flex: 1, height: 48, width: "100%" }}
            autoFocus
            placeholder="Search product from printy6"
            startAdornment={
              <InputAdornment>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Box>
      </Box>
    </Modal>
  );
}
