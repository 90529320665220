import { styled } from "@mui/material/styles";

const InputAdornment = styled("div")`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export default InputAdornment;
