import staticImageLoader from "@lib/staticImageLoader";
import { Box, Grid, Typography } from "@mui/material";
import Image from "next/image";
import ebay from "public/image/ebay.svg";
import amazon from "public/image/platforms/amazon.png";
import bigcommerce from "public/image/platforms/bigcommerce.jpg";
import shopify from "public/image/platforms/shopify.png";
import woocommerce from "public/image/platforms/woocommerce.png";
import youtube from "public/image/youtube.svg";
import React from "react";

type Props = {};

const Card = ({ image, name, description }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        p: 1.5,
      }}
    >
      <Box
        sx={{
          width: 70,
          height: 70,
          bgcolor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: (theme) => theme.shadows[2],
          // boxShadow: "0 2px 2px rgba(0,0,0,.19)",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            borderRadius: "100%",
            fontSize: 0,
            overflow: "hidden",
            bgcolor: "grey.300",
          }}
        >
          {image}
        </Box>
      </Box>
      <Box sx={{ flex: 1, width: 0, pl: 2 }}>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          {description}
        </Typography>
      </Box>
    </Grid>
  );
};

export default function Intergration({}: Props): React.ReactElement {
  return (
    //   <Grid container spacing={5}>
    <Box
      sx={{
        px: 2,
      }}
    >
      <Box>
        <Typography align="center" variant="h4">
          More marketplace integration is coming soon
        </Typography>
        <Typography align="center" variant="body1" sx={{ mt: 1.5 }}>
          Create topical things related products for your audiences and
          customers.
        </Typography>
      </Box>

      <Box
        sx={{
          maxWidth: (theme) => theme.breakpoints.values.xl,
          mx: "auto",
          mt: 6,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <Grid container columnSpacing={1} rowSpacing={4}>
          <Card
            image={
              <Image
                src={shopify}
                alt="shopify"
                width={40}
                height={40}
                loader={staticImageLoader}
              />
            }
            name="Shopify"
            description="Shopify is a very easy to use platform, user-friendly interface and helpful support services"
          />
          <Card
            image={
              <Image
                src={woocommerce}
                alt="woocommerce"
                width={40}
                height={40}
                loader={staticImageLoader}
              />
            }
            name="Woocommerce"
            description="A free plugin for WordPress blogs. It doesn't include hosting, but it gives you more flexibility to customize your store."
          />
          <Card
            image={
              <Image
                src={youtube}
                alt="youtube"
                width={40}
                height={40}
                loader={staticImageLoader}
              />
            }
            name="Youtube"
            description="YouTube With 5 billion video views being watched each day and a good marketing channels."
          />

          <Card
            image={
              <Image
                src={amazon}
                alt="amazon"
                width={40}
                height={40}
                loader={staticImageLoader}
              />
            }
            name="Amazon"
            description=" Amazon is one of the most popular online marketplaces. It's a great choice if you already have an online store and want to sell on another channel."
          />
          <Card
            image={
              <Image
                src={ebay}
                alt="ebay"
                width={40}
                height={40}
                loader={staticImageLoader}
              />
            }
            name="Ebay"
            description="eBay is an excellent choice for those who already have a store and are looking for an additional selling channel."
          />
          <Card
            image={
              <Image
                src={bigcommerce}
                alt="bigcommerce"
                width={40}
                height={40}
                loader={staticImageLoader}
              />
            }
            name="BigCommerce"
            description="BigCommerce enables multi-channel selling, so you can sell on your store and on other marketplaces and social sites, like Etsy and Facebook."
          />
        </Grid>
      </Box>
    </Box>
  );
}
