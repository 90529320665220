import { Box, Typography } from "@mui/material";
import { teal } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import type { RootState } from "@stores/store";
import staticImageLoader from "lib/staticImageLoader";
import Image from "next/image";
import HeadImage from "public/image/landing_page/category/head.png";
import * as React from "react";
import { useSelector } from "react-redux";
import SearchInput from "../searchInput";
import BigOrderInquire from "./bigOrderInquire";

type Props = {};

export const Container = styled("div")<{ size?: "lg" | "xl" }>(
  ({ theme, size = "lg" }) => ({
    maxWidth:
      size === "lg" ? theme.breakpoints.values.lg : theme.breakpoints.values.xl,
    marginLeft: "auto",
    marginRight: "auto",
    // paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  })
);

export default function CustomHome({}: Props): React.ReactElement {
  const app = useSelector((store: RootState) => store.app);
  const [isLoadBigOrderInquire, setLoadBigOrderInquire] = React.useState(false);

  return (
    <Box
      sx={{
        // maxWidth: (theme) => theme.breakpoints.values.md,
        // mx: "auto",
        height: "100%",
        bgcolor: teal[100],
        // bgcolor: "primary.main",
      }}
    >
      {/* <Image src={headIamge} width={450} height={400} /> */}
      <Container
        size="xl"
        sx={{
          position: "relative",
          display: {
            xs: "block",
            md: "flex",
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            px: 2,
            maxWidth: 705,
            mx: "auto",
            my: "auto",
            overflow: "hidden",
            pt: {
              xs: 2,
              md: 0,
            },
          }}
        >
          {/* <Typography color="textSecondary" align="center" variant="subtitle2">
          PRODUCT CATALOG
        </Typography> */}
          <Typography
            variant="h2"
            align="center"
            sx={{
              mt: 1,
              lineHeight: 1.2,
              display: {
                xs: "none",
                sm: "block",
              },
            }}
            color="inherit"
          >
            The Ultimate
            <br />
            Apparel Print on Demand & sourcing platform
          </Typography>
          <Typography
            variant="h3"
            align="center"
            sx={{
              mt: 1,
              lineHeight: 1.2,
              display: {
                xs: "block",
                sm: "none",
              },
            }}
            color="inherit"
          >
            The Ultimate
            <br />
            Apparel souring platform
          </Typography>
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
            sx={{ mt: 2 }}
          >
            Customize the products with your brand and sell everywhere,
            On-demand production and No MOQ help to reduce your inventory and
            optimize your sales!
          </Typography>

          <Box sx={{ mt: 3 }}>
            <BigOrderInquire />
          </Box>
          {/* <DoubleArrowIcon
            // color="primary"
            sx={{
              fontSize: 40,
              mx: "auto",
              mt: 4,
              display: "block",
              transform: "rotate(90deg)",
            }}
          /> */}
          <Box
            sx={{
              mt: { xs: 0, md: 4 },
              mx: "auto",
              // display: "none",
              position: "relative",
              maxWidth: (theme) => theme.breakpoints.values.sm,
            }}
          >
            <Box
              sx={{
                width: "100%",
                zIndex: 2,
                my: "auto",
                top: "calc(50% - 24px)",
                display: "none",
                position: {
                  xs: "absolute",
                  md: "relative",
                },
              }}
            >
              <SearchInput />
            </Box>
            <Box
              sx={{
                textAlign: "center",
                fontSize: 0,
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
            >
              <Image
                src={HeadImage}
                width={360}
                height={300}
                loader={staticImageLoader}
                alt="Printy6 Apparel Print on Demand"
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: 500,
            height: 400,
            display: {
              xs: "none",
              md: "flex",
            },
            // position: "relative",
          }}
        >
          {/* <Typography variant="h2">
            Fashion
            <br />
            ODM
          </Typography> */}
          <Image
            src={HeadImage}
            objectPosition="bottom"
            width={1000}
            height={800}
            loader={staticImageLoader}
            alt="Printy6 Apparel Print on Demand"
          />
        </Box>
      </Container>
    </Box>
  );
}
