import { initAppData } from "@utils/initAppData";
import CustomHome from "containers/custom/home";
import Layout from "layout/landingPage";

type Props = {};

export default function Custom({}: Props) {
  return (
    <Layout>
      <CustomHome />
    </Layout>
  );
}

Custom.getInitialProps = async (ctx) => {
  await initAppData(ctx);

  return {
    // device: ctx?.req?.device.type.toUpperCase() || null,
  };
};
