import { LoadingButton as Button } from "@mui/lab";
import {
  Box,
  InputLabel,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";

type Props = {
  isLoading: boolean;
  formData: any;
  handleChange: any;
};

const initFormData = {
  name: "",
  email: "",
  phone: "",
  website_url: "",
  other_info: "",
};

type FormDataKey = keyof typeof initFormData;

export default function Form({
  isLoading,
  formData,
  handleChange,
}: Props): React.ReactElement {
  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <InputLabel sx={{ mb: 1 }}>Full name *</InputLabel>
        <TextField
          id="oem-order-inquire-name"
          required
          fullWidth
          name="name"
          size="small"
          value={formData["name"]}
          onChange={(ev) => handleChange("name", ev.target.value)}
          placeholder="John Smith"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <InputLabel sx={{ mb: 1 }}>Email *</InputLabel>
        <TextField
          id="oem-order-inquire-email"
          required
          fullWidth
          name="email"
          size="small"
          value={formData["email"]}
          onChange={(ev) => handleChange("email", ev.target.value)}
          placeholder="Name@example.com"
          variant="outlined"
          type="email"
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <InputLabel sx={{ mb: 1 }}>Phone number *</InputLabel>
        <TextField
          id="oem-order-inquire-phone"
          required
          fullWidth
          name="phone"
          size="small"
          value={formData["phone"]}
          onChange={(ev) => handleChange("phone", ev.target.value)}
          placeholder="+1234 567 8901"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <InputLabel sx={{ mb: 1 }}>Website URL</InputLabel>
        <TextField
          id="oem-order-inquire-website-url"
          fullWidth
          name="website_url"
          size="small"
          value={formData["website_url"]}
          onChange={(ev) => handleChange("website_url", ev.target.value)}
          placeholder="www.example.com"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <InputLabel sx={{ mb: 1 }}>What you want to discuss</InputLabel>
        <TextField
          id="oem-order-inquire-other-info"
          fullWidth
          name="other-info"
          size="small"
          value={formData["other_info"]}
          onChange={(ev) => handleChange("other_info", ev.target.value)}
          variant="outlined"
          multiline
          minRows={3}
        />
      </Box>
      <Button
        variant="contained"
        size="large"
        sx={{ mt: 2 }}
        type="submit"
        loading={isLoading}
      >
        Submit
      </Button>
      <Box sx={{ mt: 1 }}>
        <Typography variant="caption" color="textSecondary">
          This site is protected by reCAPTCHA and the Google{" "}
          <MuiLink
            target="_blank"
            href="https://policies.google.com/privacy?hl=en_US"
            rel="nofollow noopener"
          >
            Privacy Policy
          </MuiLink>{" "}
          and{" "}
          <MuiLink
            target="_blank"
            href="https://policies.google.com/terms?hl=en_US"
            rel="nofollow noopener"
          >
            Terms of Service
          </MuiLink>{" "}
          apply.
        </Typography>
      </Box>
    </Box>
  );
}
