import staticImageLoader from "@lib/staticImageLoader";
import { Box, Typography } from "@mui/material";
import { teal } from "@mui/material/colors";
import Form from "components/notionForm/oemInquire";
import Image from "next/image";
import contentImage from "public/image/custom-home-head.svg";
import * as React from "react";

type Props = {};

export default function CustomInquire({}: Props): React.ReactElement {
  return (
    <Box>
      <Typography
        variant="h3"
        sx={
          {
            // display: { xs: "block", lg: "none" },
          }
        }
      >
        Can't find the style you need in our product list?
      </Typography>
      <Typography
        variant="body1"
        sx={{ mt: 2, display: { xs: "block", lg: "block" } }}
      >
        Just provide us with the Tech-pack or a few sample photos, we will make
        and provide samples for you from scratch and finish them within a few
        days. Then we will discuss the details with you and modify them
        according to your requirements until you are satisfied.
      </Typography>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          {/* <Typography
            variant="h3"
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          >
            Can't find the style you need in our product list?
          </Typography> */}
          {/* <Typography
            variant="body1"
            sx={{ mt: 2, display: { xs: "none", lg: "block" } }}
          >
            Just provide us with the Tech-pack or a few sample photos, we will
            make and provide samples for you from scratch and finish them within
            a few days. Then we will discuss the details with you and modify
            them according to your requirements until you are satisfied.
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
            }}
          >
            <Box
              sx={{
                pt: 8,
                mx: {
                  xs: -2,
                  sm: 0,
                },
              }}
            >
              <Box
                sx={{
                  bgcolor: teal[100],
                  px: {
                    xs: 2,
                    sm: 4,
                  },
                  pb: 4,
                  borderRadius: "0 0 8px 8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Form />
                <Box
                  sx={{
                    alignItems: "flex-end",
                    maxWidth: { xs: "none", sm: "none", md: 800, lg: 800 },
                    justifyContent: { xs: "center", md: "inherit" },
                    mt: { xs: 4, md: 0 },
                    display: {
                      xs: "flex",
                      lg: "none",
                    },
                  }}
                >
                  <Image
                    src={contentImage}
                    width={900}
                    height={600}
                    loader={staticImageLoader}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pl: 4,
            alignItems: "flex-end",
            maxWidth: { xs: "none", sm: "none", md: 500, lg: 650, xl: 800 },
            justifyContent: { xs: "center", md: "inherit" },
            mt: { xs: 4, md: 0 },
            display: {
              xs: "none",
              lg: "flex",
            },
          }}
        >
          <Image
            src={contentImage}
            width={900}
            height={600}
            loader={staticImageLoader}
          />
        </Box>
      </Box>
    </Box>
  );
}
