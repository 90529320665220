import SearchIcon from "@mui/icons-material/SearchRounded";
import { Box } from "@mui/material";
import animateScrollTo from "animated-scroll-to";
import Input, { InputAdornment } from "components/input";
import * as React from "react";
import SearchModal from "./searchModal";

type Props = {};

export default function SearchInput({}: Props): React.ReactElement {
  const [width, setWidth] = React.useState(0);
  const [isOpen, setOpen] = React.useState(false);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });
  const inputRef = React.useRef(null);

  const onFocus = () => {
    setWidth(inputRef.current.offsetWidth);
    setPosition({
      top: inputRef.current.offsetTop,
      left: inputRef.current.offsetLeft,
    });
    animateScrollTo(0, {
      maxDuration: 200,
      onComplete: () => {
        setOpen(true);
        inputRef.current.blur();
      },
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Input
        ref={inputRef}
        sx={{ flex: 1, height: 48, width: "100%" }}
        onFocus={onFocus}
        placeholder="Search product from printy6"
        startAdornment={
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        }
      />
      <SearchModal
        isOpen={isOpen}
        setOpen={setOpen}
        position={position}
        width={width}
      />
      {/* <Button variant="contained" sx={{ height: 40, ml: 2, width: 100 }}>
        Search
      </Button> */}
    </Box>
  );
}
