import { appInitialLoad } from "@services/app";
import Router from "next/router";

// export function ctxResponse(ctx, response) {
//   const { res } = ctx;
//   if (response._cookies != undefined) {
//     for (let cookie of response._cookies) {
//       res.append("Set-Cookie", cookie);
//     }
//   }
// }

export function checkNeedLoadInitData(ctx, { guard }) {
  let { reduxStore, req } = ctx;

  const { products, app } = reduxStore.getState();
  const isServer = !!req;

  //如果在服务端，那么执行初始化请求
  if (isServer) {
    return true;
  }

  //在client中，如果目标页面需要登录，并且目前状态是未登录，则执行初始化请求
  if (!app.user && guard == true) {
    return true;
  }

  return false;
}

export async function initAppData(ctx, { guard = false } = {}) {
  //写入API服务器响应cookie到Render服务器
  const { req, res, reduxStore } = ctx;

  if (checkNeedLoadInitData(ctx, { guard })) {
    let response = await appInitialLoad({
      headers:
        req && req.headers.cookie ? { cookie: req.headers.cookie } : undefined,
    });

    if (response.user == false && guard) {
      if (res) {
        res.writeHead(302, {
          Location: "/login",
        });
        return res.end();
      } else {
        return Router.push("/login");
      }
    }

    // ctxResponse(ctx, response);
    let initData = {
      initAppData: {
        user: response.user,
        shopify_review: response.shopify_review ? response.shopify_review : {},
        catalog: response.catalog,
        recommend: response.recommend,
        collection: response.collection,
        favorites: response.favorites,
      },
    };

    let isServer = true;

    await ctx.reduxStore.execSagaTasks(isServer, (dispatch) => {
      dispatch({ type: "APP_INIT", data: initData.initAppData });
    });

    return initData.initAppData;
  }

  const state = reduxStore.getState();

  return state.app;
}
