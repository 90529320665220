import staticImageLoader from "@lib/staticImageLoader";
import { Box, Grid, Link as MuiLink, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";
import KidsImage from "public/image/landing_page/category/category-kids-clothing.png";
import MensClothingImage from "public/image/landing_page/category/category-mens-clothing.png";
import JumpsuitImage from "public/image/landing_page/category/category-womems-jumpsuit.png";
import BlouseImage from "public/image/landing_page/category/category-womens-blouse.png";
import DressImage from "public/image/landing_page/category/category-womens-clothing.png";
import SkirtImage from "public/image/landing_page/category/category-womens-skirt.png";
import * as React from "react";

type Props = {};

const CATEGORY_LIST = [
  {
    name: "Dress",
    key: "womens-dress",
    image: DressImage,
  },
  {
    name: "Jumpsuit",
    key: "womens-jumpsuit",
    image: JumpsuitImage,
  },
  {
    name: "Blouse & Shirt",
    key: "womens-blouse",
    image: BlouseImage,
  },
  {
    name: "Skirt",
    key: "womens-skirts",
    image: SkirtImage,
  },
  {
    name: "Men's Clothing",
    key: "mens-clothing",
    image: MensClothingImage,
  },
  {
    name: "Kids & Babies",
    key: "kids-and-babies-clothing",
    image: KidsImage,
  },
];

export const Container = styled("div")<{ size?: "lg" | "xl" }>(
  ({ theme, size = "lg" }) => ({
    maxWidth:
      size === "lg" ? theme.breakpoints.values.lg : theme.breakpoints.values.xl,
    marginLeft: "auto",
    marginRight: "auto",
    // paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  })
);

export default function CategoryList({}: Props): React.ReactElement {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        All Categories
      </Typography>
      <Grid container spacing={2}>
        {CATEGORY_LIST.map((item) => {
          return (
            <Grid key={item.key} item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  bgcolor: "#f2f2f2",
                  display: "flex",
                  // border: "1px solid",
                  // borderColor: (theme) => theme.palette.divider,
                  borderRadius: 1,
                  overflow: "hidden",
                  fontSize: 0,
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    width: "60%",
                    "& img": {
                      width: "100%",
                      display: "block",
                      // marginLeft: "auto",
                      // marginRight: "auto",
                    },
                  }}
                >
                  <Image
                    src={item.image}
                    width={1000}
                    height={800}
                    loader={staticImageLoader}
                    alt={item.name}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    justifyContent: "center",
                    px: 2,
                  }}
                >
                  <Typography variant="h5" align="center">
                    {item.name}
                  </Typography>
                  {/* <MuiLink
                        underline="always"
                        variant="subtitle1"
                        align="center"
                        // color="inherit"
                        sx={{ mt: 1 }}
                      >
                        View
                      </MuiLink> */}
                  <Box
                    sx={{
                      mt: 2,
                      width: 150,
                      mx: "auto",
                      position: "relative",
                    }}
                  >
                    {/* <Box
                          sx={(theme) => ({
                            zIndex: 1,
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 2,
                            bgcolor: theme.palette.common.black,
                          })}
                        /> */}

                    <Link href={`/custom/${item.key}`} passHref>
                      <MuiLink color="inherit" underline="none">
                        <Box
                          component="button"
                          sx={(theme) => ({
                            py: 1,
                            zIndex: 2,
                            width: "100%",
                            borderRadius: 1,
                            border: "2px solid",
                            borderColor: theme.palette.common.black,
                            // borderColor: "#f2f2f2",
                            // position: "absolute",
                            // bgcolor: theme.palette.primary.main,
                            bgcolor: theme.palette.common.black,
                            color: theme.palette.common.white,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.subtitle1.fontWeight,
                            fontSize: theme.typography.body1.fontSize,
                            cursor: "pointer",
                          })}
                        >
                          View
                        </Box>
                      </MuiLink>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
