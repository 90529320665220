import staticImageLoader from "@lib/staticImageLoader";
import { Box, Grid } from "@mui/material";
import Image from "next/image";
// import clouth from ""
import clothing from "public/image/homepage/clothing.jpg";
import materialImage from "public/image/homepage/material.jpg";
import patternImage from "public/image/homepage/pattern.png";
import * as React from "react";
type Props = {};

export default function Case({}: Props): React.ReactElement {
  return (
    <Box sx={{ display: "flex", position: "relative", maxWidth: 700 }}>
      <Grid
        container
        direction={{
          xs: "row-reverse",
          md: "row",
        }}
      >
        <Grid
          xs={12}
          sm={4}
          item
          sx={{
            height: { xs: "auto", md: "100%" },
            width: { xs: "100%", md: "auto" },
            display: "flex",
            alignItems: "flex-start",
            px: (theme) => ({
              xs: 0,
              sm: theme.spacing(1),
            }),
            position: "relative",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Box maxWidth={600}>
            <Box sx={{ background: "#eee", mb: 2, fontSize: 0 }}>
              <Image src={patternImage} loader={staticImageLoader} />
            </Box>
            <Image src={materialImage} loader={staticImageLoader} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            fontSize: 0,
            height: { xs: "auto", md: "100%" },
            width: { xs: "100%", md: "auto" },
            display: "flex",
            alignItems: "flex-end",
            px: (theme) => ({
              xs: 0,
              sm: theme.spacing(1),
            }),
            position: "relative",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Image src={clothing} loader={staticImageLoader} />
        </Grid>
      </Grid>
    </Box>
  );
}
