import LabelIcon from "@mui/icons-material/LabelOutlined";
import ManageHistoryIcon from "@mui/icons-material/ManageHistoryOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import Link from "next/link";
import * as React from "react";
// import Editor from "./editor";
import Case from "./case";

type Props = {};

export default function DesingCase({}: Props): React.ReactElement {
  return (
    <Box>
      <Typography
        variant="h3"
        sx={{
          display: { xs: "block", lg: "none" },
        }}
      >
        Leading rendering technology
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 2,
          display: { xs: "block", lg: "none" },
        }}
      >
        Printy6 has industry-leading image rendering technology and
        comprehensive design tools. Upload your patterns, or choose from our
        library of hundreds of thousands of free patterns, you'll get a highly
        emulating rendered mockup. What possible benefit will Printy6 customizer
        bring you?
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: {
            xs: "center",
            lg: "flex-end",
          },
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box sx={{ flex: 1, width: { xs: "100%", md: 0 } }}>
          <Typography
            variant="h3"
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          >
            Leading rendering technology
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              display: { xs: "none", lg: "block" },
            }}
          >
            Printy6 has industry-leading image rendering technology and
            comprehensive design tools. <br />
            Upload your patterns, or choose from our library of hundreds of
            thousands of free patterns, <br />
            you'll get a highly emulating rendered mockup. <br />
            What possible benefit will Printy6 customizer bring you?
          </Typography>
          <Stack component="ul" spacing={2} sx={{ pl: 0, mt: 4 }}>
            <Box sx={{ display: "flex", alignItems: "flex-top" }}>
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(140deg, rgba(77,182,172,0.5) 0%, rgba(178,223,219,0.4) 100%)",
                }}
              >
                <LabelIcon fontSize="medium" sx={{ color: "#00897b" }} />
              </Box>
              <Box sx={{ pl: 2, flex: 1, width: 0 }}>
                <Typography variant="subtitle1">
                  What you see is what you get
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  The final product will be rendered in the preview window in
                  real-time when you put your pattern on.
                  <br />
                  The pattern position on the rendered image is almost the same
                  as the final product
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-top" }}>
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(140deg, rgba(77,182,172,0.5) 0%, rgba(178,223,219,0.4) 100%)",
                }}
              >
                <ThumbUpAltIcon fontSize="medium" sx={{ color: "#00897b" }} />
              </Box>
              <Box sx={{ pl: 2, flex: 1, width: 0 }}>
                <Typography variant="subtitle1">
                  Rapid market validation is possible
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  It can take weeks or even months to get from design to final
                  product in a traditional outsourced manufacturing process.
                  With Printy6‘s simulation rendering technology, you can design
                  a product in five minutes.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-top" }}>
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(140deg, rgba(77,182,172,0.5) 0%, rgba(178,223,219,0.4) 100%)",
                }}
              >
                <ManageHistoryIcon
                  fontSize="medium"
                  sx={{ color: "#00897b" }}
                />
              </Box>
              <Box sx={{ pl: 2, flex: 1, width: 0 }}>
                <Typography variant="subtitle1">
                  Make your idea profitable quickly
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Quickly turn your photo ideas into products and share the
                  product link with your friends or audiences, you get the
                  money, and our drop-shipping service will take care of the
                  rest.
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Box sx={{ pl: 8 }}>
            <Link color="primary" href="/custom" passHref>
              <MuiLink underline="hover">Try it yourself</MuiLink>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: {
              lg: 800,
              md: 500,
            },
            pl: {
              xs: 0,
              md: 2,
            },
            mx: "auto",
            display: {
              xs: "flex",
              md: "block",
            },
            mt: {
              xs: 4,
              md: 0,
            },
            justifyContent: "center",
          }}
        >
          <Case />
        </Box>
      </Box>
    </Box>
  );
}
