import ChevronLeftIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import { Box, Divider, Link as MuiLink, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MatchMap, useSliderPreview } from "components/swiper";
import Link from "next/link";
import * as React from "react";
import "swiper/css";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import ProductCard from "../productCard";

export const Container = styled("div")<{ size?: "lg" | "xl" }>(
  ({ theme, size = "lg" }) => ({
    maxWidth:
      size === "lg" ? theme.breakpoints.values.lg : theme.breakpoints.values.xl,
    marginLeft: "auto",
    marginRight: "auto",
    // paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // position: "relative",
  })
);

export const Button = styled("button")(({ theme }) => ({
  width: 56,
  height: 56,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "100%",
  boxShadow: theme.shadows[8],
  border: 0,
  position: "absolute",
  top: "calc(50% - 28px)",
  zIndex: 2,
  cursor: "pointer",
  background: "#ffffff",
  "&:disabled": {
    color: theme.palette.grey[300],
  },
}));

type Props = {
  title?: string;
  templates: any[];
  matchMap?: MatchMap;
};

const SlidePrevButton = () => {
  const swiper = useSwiper();
  const [disabled, setDisabled] = React.useState(swiper.isBeginning);

  swiper.on("activeIndexChange", function (e) {
    setDisabled(e.isBeginning);
  });

  return (
    <Button
      sx={{ left: 24 }}
      onClick={() => swiper.slidePrev()}
      disabled={disabled}
      aria-label="previous product"
    >
      <ChevronLeftIcon fontSize="large" />
    </Button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  const [disabled, setDisabled] = React.useState(swiper.isEnd);

  swiper.on("activeIndexChange", function (e) {
    setDisabled(e.isEnd);
  });

  return (
    <Button
      sx={{ right: 24 }}
      onClick={() => swiper.slideNext()}
      disabled={disabled}
      aria-label="next product"
    >
      <ChevronRightIcon fontSize="large" />
    </Button>
  );
};

export default function ProductSwiper({
  title,
  templates,
  matchMap = {
    xs: "auto",
    sm: "auto",
    md: 3,
    lg: 4,
    xl: 5,
  },
}: Props): React.ReactElement {
  const { slidesPerView, sm: matchesXS } = useSliderPreview(matchMap);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {title && (
        <Divider sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h4">{title}</Typography>
        </Divider>
      )}
      <Container size="xl">
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={16}
          style={{ padding: 16, marginLeft: -16, marginRight: -16 }}
        >
          {templates.map((recommend) => {
            return (
              <SwiperSlide
                key={recommend.id}
                style={{
                  width: matchesXS ? "80%" : "auto",
                }}
              >
                <Link href={`/design/${recommend.slug}`} passHref>
                  <MuiLink underline="none" color="inherit">
                    <ProductCard template={recommend} />
                  </MuiLink>
                </Link>
              </SwiperSlide>
            );
          })}
          <SlidePrevButton />
          <SlideNextButton />
        </Swiper>
      </Container>
    </Box>
  );
}
