import staticImageLoader from "@lib/staticImageLoader";
import LabelIcon from "@mui/icons-material/LabelOutlined";
import ManageHistoryIcon from "@mui/icons-material/ManageHistoryOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";
import contentImage from "public/image/brand-service.svg";
import * as React from "react";

type Props = {};

export default function BrandService({}: Props): React.ReactElement {
  return (
    <Box>
      <Typography
        variant="h3"
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        Overall Brand Service
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: {
            xs: "column-reverse",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            mr: {
              xs: 0,
              md: 6,
            },
            mt: {
              xs: 4,
              md: 0,
            },
            height: { xs: "auto", md: "100%" },
            width: { xs: "100%", md: "auto" },
            display: {
              xs: "flex",
              md: "block",
            },
            justifyContent: "center",
            maxWidth: {
              xs: "none",
              sm: "none",
              md: 400,
              lg: 600,
            },
          }}
        >
          <Image
            src={contentImage}
            width={600}
            height={460}
            loader={staticImageLoader}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            width: {
              xs: "100%",
              md: 0,
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            Overall Brand Service
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
            }}
          >
            Printy6 offers a full range of professional ODM & OEM services,
            allowing brands to focus entirely on brand operations and sales.
            Through Printy6 to achieve complete production and manufacturing
            trusteeship, giving the brand efficient and stable back-end supply
            capacity.
          </Typography>
          <Stack component="ul" spacing={2} sx={{ pl: 0, mt: 4 }}>
            <Box sx={{ display: "flex", alignItems: "flex-top" }}>
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(140deg, rgba(77,182,172,0.5) 0%, rgba(178,223,219,0.4) 100%)",
                }}
              >
                <LabelIcon fontSize="medium" sx={{ color: "#00897b" }} />
              </Box>
              <Box sx={{ pl: 2, flex: 1, width: 0 }}>
                <Typography variant="subtitle1">
                  Customizable brand packaging
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Including but not limited to washing labels, packaging boxes,
                  packaging bags, branded parts, etc. Your products will be
                  fully in line with your brand specifications.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-top" }}>
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(140deg, rgba(77,182,172,0.5) 0%, rgba(178,223,219,0.4) 100%)",
                }}
              >
                <ThumbUpAltIcon fontSize="medium" sx={{ color: "#00897b" }} />
              </Box>
              <Box
                sx={{
                  pl: 2,
                  flex: 1,
                  width: 0,
                }}
              >
                <Typography variant="subtitle1">
                  Timely and stable delivery
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  The production efficiency has been dramatically improved
                  through our highly informationized production process. Even if
                  your order contains many different SKUs, everything is orderly
                  and controllable. No matter where you are, we will deliver
                  your order accurately and timely.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-top" }}>
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(140deg, rgba(77,182,172,0.5) 0%, rgba(178,223,219,0.4) 100%)",
                }}
              >
                <ManageHistoryIcon
                  fontSize="medium"
                  sx={{ color: "#00897b" }}
                />
              </Box>
              <Box sx={{ pl: 2, flex: 1, width: 0 }}>
                <Typography variant="subtitle1">Quality guarantee</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  AATCC(American Association of Textile Chemists and Colorists)
                  and ISO standard fabrics to ensure the quality of our
                  products.
                </Typography>
              </Box>
            </Box>
          </Stack>
          {/* <Box sx={{ pl: 8 }}>
            <Link color="primary">Learn More</Link>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
