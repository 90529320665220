import type { RootState } from "@stores/store";
import * as React from "react";
import { useSelector } from "react-redux";
import ProductSwiper from "../productSwiper";

type Props = {};

export default function RecommandProduct({}: Props): React.ReactElement {
  const recommends = useSelector((store: RootState) => store.app.recommend);

  return <ProductSwiper title="You might also like" templates={recommends} />;
}
