import StoreIcon from "@mui/icons-material/StoreRounded";
import { Typography } from "@mui/material";
import dynamic from "next/dynamic";
import * as React from "react";

const BigOrderInquireForm = dynamic(
  () => import("components/notionForm/bigOrderInquire")
);

type Props = {};

export default function CustomHome({}: Props): React.ReactElement {
  const [isLoadBigOrderInquire, setLoadBigOrderInquire] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setLoadBigOrderInquire(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Typography
        // color="primary"
        onClick={handleOpen}
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StoreIcon sx={{ mr: 0.5 }} />
        Doing large volume?
      </Typography>
      {isLoadBigOrderInquire && (
        <BigOrderInquireForm open={open} handleClose={handleClose} />
      )}
    </React.Fragment>
  );
}
