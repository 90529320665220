import staticImageLoader from "@lib/staticImageLoader";
import { Box, Paper, Typography } from "@mui/material";
import fetcher from "lib/fetcher";
import Image from "next/image";
import { useSnackbar } from "notistack";
import ContactImage from "public/image/contact-us.svg";
import * as React from "react";
import Form from "./form";

type Props = {};

const initFormData = {
  name: "",
  email: "",
  phone: "",
  website_url: "",
  other_info: "",
};

type FormDataKey = keyof typeof initFormData;

export default function OemInquire({}: Props): React.ReactElement {
  const [isSuccess, setSuccess] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(initFormData);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setLoading(true);
    fetcher({
      url: "/api/v2/form/oemInquire",
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setLoading(false);
        setFormData(initFormData);
        enqueueSnackbar("We'll happily be in touch with you very soon!", {
          variant: "success",
        });
        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);

        enqueueSnackbar("Failed to submit form.", {
          variant: "error",
        });
      });
  };

  const handleChange = (
    key: FormDataKey,
    value: typeof initFormData[FormDataKey]
  ) => {
    setFormData((state) =>
      Object.assign({}, state, {
        [key]: value,
      })
    );
  };

  if (isSuccess) {
  }

  return (
    <form onSubmit={handleSubmit}>
      <Paper
        elevation={4}
        sx={{
          maxWidth: 600,
          position: "relative",
          top: -32,
          p: {
            xs: 2,
            md: 4,
          },
        }}
      >
        {isSuccess && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Image
                src={ContactImage}
                width={800}
                height={800}
                loader={staticImageLoader}
              />
              <Typography variant="h6" align="center">
                Ta-da! 🥳
              </Typography>
              <Typography variant="h6" sx={{ mt: 1 }} align="center">
                We'll happily be in touch with you very soon!
              </Typography>
            </Box>
          </Box>
        )}
        {!isSuccess && (
          <Form
            isLoading={isLoading}
            formData={formData}
            handleChange={handleChange}
          />
        )}
      </Paper>
    </form>
  );
}
