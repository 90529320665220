import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import InputUnstyled, {
  inputUnstyledClasses,
  InputUnstyledProps,
} from "@mui/base/InputUnstyled";
import { styled, SxProps, Theme } from "@mui/material";
import * as React from "react";

const blue = {
  100: "#DAECFF",
  200: "#80BFFF",
  400: "#3399FF",
  600: "#0072E5",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: ${theme.typography.fontFamily};
  display: flex;
  font-weight: 500;
  border: 1px solid;
  border-color: ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[400]
  };
  border-radius: 4px;
  background: ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[50]
  };
  align-items: center;
  justify-content: center;

  &.${inputUnstyledClasses.focused} {
    border-color: ${
      theme.palette.mode === "dark"
        ? theme.palette.grey[50]
        : theme.palette.primary.main
    };
    outline: 1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.grey[50]
        : theme.palette.primary.main
    };
  }

  &:hover {
    border-color: ${
      theme.palette.mode === "dark"
        ? theme.palette.grey[50]
        : theme.palette.primary.main
    };
    outline: 1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.grey[50]
        : theme.palette.primary.main
    };
  }
`
);

const StyledInputElement = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 8px;
  outline: 0;
`
);

const IconButton = styled(ButtonUnstyled)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
`;

const InputAdornment = styled("div")`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Input = React.forwardRef(function CustomInput(
  props: InputUnstyledProps & {
    sx?: SxProps<Theme>;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { components, ...other } = props;

  return (
    <InputUnstyled
      components={{
        Root: StyledInputRoot,
        Input: StyledInputElement,
        ...components,
      }}
      {...other}
      ref={ref}
    />
  );
});

export default Input;
